import React from "react";
import { useTranslation } from "react-i18next";
const MainFooter = () => {
  const { t } = useTranslation();
  return (
    <>
      <footer className="footer pt-3">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-lg-between">
            <div className="col-lg-6 mb-lg-0 mb-4">
              <div className="copyright text-center text-sm text-muted text-lg-start">
                <a className="copyRight" href="/Home">
                  ©{" "}
                  {new Date().getFullYear() == 2023
                    ? "2023"
                    : "2023-" + new Date().getFullYear()}{" "}
                  - {t("systemName")}
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <ul className="nav nav-footer justify-content-center justify-content-lg-end">
                <li className="nav-item">
                  <a href="#" className="nav-link text-muted" target="_blank">Link 1</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default MainFooter;
