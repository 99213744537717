import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useSubmitData } from "../../../hooks/useSubmitData";
import FormLayout from '../../Generic/FormLayout';
import FormInput from '../../Generic/FormInput';
import FormSelect from '../../Generic/FormSelect';
import { useStore } from "../../../stores/store";
import { useSetMenu } from "../../../hooks/useSetMenu";
import { format } from "date-fns";
const Add = () => {
  const navigate = useNavigate();
  const { mainStore } = useStore();
  const axiosPrivate = useAxiosPrivate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  useSetMenu(mainStore.MergerAndAcquisitionMenuType, mainStore.MergerAndAcquisitionMenuNo);
  const onCancel = () => navigate(-1);
  
  const schema = yup.object({
    companyId: yup.number().required(t("selectRequired")),
    companyMAndAById: yup.number().required(t("selectRequired")),
    foundCompYear: yup.date().required(t('requiredField')),
    aAndMDate: yup.date().required(t('requiredField')),
    // The lifetime field is calculated and does not need to be included in the schema
    infoCompanyFound: yup.string(),
    infoWhenMerged: yup.string(),
    otherDetails: yup.string(),
    bmFound: yup.string(),
    bmMerged: yup.string(),
  });

  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const foundCompYear = watch("foundCompYear");
  const aAndMDate = watch("aAndMDate");

  useEffect(() => {
    if (foundCompYear && aAndMDate) {
      const start = new Date(foundCompYear);
      const end = new Date(aAndMDate);
      const lifetime = Math.round((end - start) / (1000 * 60 * 60 * 24));
      setValue("lifetime", lifetime >= 0 ? lifetime : ''); // Set the lifetime or clear if the dates are invalid
    }
  }, [foundCompYear, aAndMDate, setValue]);

  const sendData = useSubmitData(axiosPrivate, "/MergersAndAcquisitions", "/MergeAndAcquisition/List");
  // const onSubmit = data => sendData({...data, 
  //   lifetime: undefined,
  //   endDate:format(new Date(data.endDate), "yyyy-MM-dd"),
  //   startDate:format(new Date(data.startDate), "yyyy-MM-dd"),
  //   await sendData(formData);
  // }); // Do not send the calculated lifetime to the backend
  const onSubmit = async (data) => {
    const formattedData = {
      ...data,
      foundCompYear:format(new Date(data.foundCompYear), "yyyy-MM-dd"),
      aAndMDate:format(new Date(data.aAndMDate), "yyyy-MM-dd"),
      lifetime: undefined
    };
   
    await sendData(formattedData);
  };

  const [companies, setCompanies] = useState([]);
  useEffect(() => {
    const loadCompanies = async () => {
      try {
        const response = await axiosPrivate.get("/Company/SelectOptions");
        setCompanies(response.data);
      } catch (err) {
        enqueueSnackbar(t("loadDataError"), { variant: "error" });
      }
    };
    loadCompanies();
  }, [axiosPrivate, enqueueSnackbar, t]);

  const handleCompanyChange = (selectedOption, field) => {
    setValue(field, selectedOption.value, { shouldValidate: true });
  };

  return (
    <FormLayout title={t("mergersAndAcquisitionsCreateTitle")} onSubmit={handleSubmit(onSubmit)} onCancel={onCancel}>
      <FormSelect label={t("foundCompanyName")} name="companyId" options={companies} register={register} errors={errors} onChange={(option) => handleCompanyChange(option, 'companyId')} />
      <FormSelect label={t("MACompanyName")} name="companyMAndAById" options={companies} register={register} errors={errors} onChange={(option) => handleCompanyChange(option, 'companyMAndAById')} />
      <FormInput label={t("foundCompYear")} name="foundCompYear" type="date" register={register} errors={errors} />
      <FormInput label={t("MADate")} name="aAndMDate" type="date" register={register} errors={errors} />
      <FormInput label={t("lifetime")} name="lifetime" type="number" register={register} errors={errors} disabled />
      <FormInput label={t("infoCompanyFound")} name="infoCompanyFound" register={register} errors={errors} />
      <FormInput label={t("infoWhenMerged")} name="infoWhenMerged" register={register} errors={errors} />
      <FormInput label={t("otherDetails")} name="otherDetails" register={register} errors={errors} />
      <FormInput label={t("bmFound")} name="bmFound" register={register} errors={errors} />
      <FormInput label={t("bmMerged")} name="bmMerged" register={register} errors={errors} />
    </FormLayout>
  );
};

export default Add;
