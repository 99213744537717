import { useEffect } from 'react';
import { useStore } from '../stores/store';

export const useSetMenu = (menuType, menuNo) => {
  const { mainStore } = useStore();

  useEffect(() => {
    mainStore.setMenuType(menuType);
    mainStore.setMenuNo(menuNo);
     // eslint-disable-next-line
  }, []);
};
