import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export const useEditData = (axiosPrivate, endpoint, id, setValue, navigate, additionalDataFetch) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const [notFound, setNotFound] = useState(false);

    const loadData = async () => {
        try {
            const response = await axiosPrivate.get(`${endpoint}/${id}`);
            Object.keys(response.data).forEach(key => {
                setValue(key, response.data[key]);
            });
            if (additionalDataFetch && typeof additionalDataFetch === 'function') {
                await additionalDataFetch(response.data);
            }
        } catch (err) {
            if (err.response && err.response.status === 404) {
                setNotFound(true);
            } else {
                enqueueSnackbar(t("loadDataError"), { variant: "error" });
            }
        }
    };

    const updateData = async (data) => {
        try {
            await axiosPrivate.put(`${endpoint}/${id}`, data);
            enqueueSnackbar(t("updateMessageSuccess"), { variant: "success" });
            navigate(-1);
        } catch (err) {
            enqueueSnackbar(t("updateMessageError"), { variant: "error" });
        }
    };

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, [id]);

    return { notFound, updateData };
};
