import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
const useDeleteItem = (axiosPrivate, deleteEndpoint,  deleteAction) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const deleteItem = async (id) => {
    try {
      await axiosPrivate.delete(`${deleteEndpoint}/${id}`);
      deleteAction(id);
      enqueueSnackbar(t("deleteMessageSuccess"), { variant: "success" });
      //enqueueSnackbar( t("deleteMessageSuccess"), { variant: "success" });
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t("deleteMessageError"), { variant: "error" });
      //enqueueSnackbar(t("deleteMessageError"), { variant: "error" });
    }
  };

  return deleteItem;
};

export default useDeleteItem;
