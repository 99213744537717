// components/FormLayout.js
import React from 'react';

const FormLayout = ({ title, children, onSubmit, onCancel }) => {
  return (
    <>
      <div className="row">
        <div className="col-lg-12 mb-lg-0 mb-4">
          <div className="card rounded-0">
            <div className="card-header pb-0">
              <p className="crudTitle">{title}</p>
            </div>
            <div className="card-body p-3">
              <div className="row">
                <div className="container">
                  <div className="row">
                    <form onSubmit={onSubmit}>
                      <div className="row justify-content-start">
                        {children}
                        <div className="col-lg-12">
                          <div className="d-flex justify-content-end form-group mt-3">
                            <button
                              type="button"
                              onClick={onCancel}
                              className="btn btn-md btn-secondary rounded-0 me-3"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="btn btn-md btn-primary rounded-0"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormLayout;
