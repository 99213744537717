import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import Languagedetector from "i18next-browser-languagedetector";

i18next
  .use(initReactI18next)
  .use(Languagedetector)
  .init({
    debug: false,
    fallbackLng: "lng1",
    resources: {
      //Naming Convention for attributes please use Camel Case -> camelCase
      lng1: {
        translation: {

          //Lavdimi
          requiredField: "Required Field!",
          maxLength: "Maximum length is {{max}} characters!",
          minValue: "The value must be at least {{min}}",
          maxValue: "The value must be less than or equal to {{max}}",
          integerRequired: "The value must be an integer",
          invalidNumber: "The value must be a valid number",
          selectRequired: "Selection is required",


          pageNotFoundTitle: "404: Page Not Found",
          noDataWithIdMessage: "No data with the ID you have provided.",
          goTo:"Go to",
          //translation after 23.04.2023 16:30
          privacyPolicyTextLabel: "I accept the ",
          privacyPolicyLabel: "Privacy Policy ",
          privacyPolicyErrorMsg: "You need to accept the terms and conditions.",

          //translation after 23.04.2023 16:30

          //translation after 20.01.2023 15:30
          responsibleFor: "Responsible",

          // #region General Description
          currentLanguage: "(EN)",
          languageLNG1: "(EN)",
          languageLNG2: "(Urdu)",
          languageLNG3: "(Sindi)",

          descriptionLNG1: "(EN)",
          descriptionLNG2: "(Urdu)",
          descriptionLNG3: "(Sindi)",

          systemName: "EDA System",
          login: "Login",
          reset: "Reset Password",
          changePassword: "Change Password",

          searchPlaceholder: "Search",

          cookieConsentOK: "OK",
          cookieConsentCancel: "CANCEL",
          cookieConsentMessage:
            " We use cookies to enable various functions. By pressing the OK button, you give your consent to this. And we thank you in advance.",

          settings: "Settings",
          general: "General",
          filters: "Filters",
          all: "All",
          youSearchedWith: "You searched with",
          messages: "Messages",

          //TopHeader
          profile: "Profile",
          topHeaderChangePassword: "Change Password",
          topHeaderSettings: "Settings",
          activityLog: "Activity log",
          help: "Help",
          logout: "Logout",
          topHeaderLogin: "LOGIN",

          //NotFound 404 ERROR
          error404Title: "404 ERROR",
          error404Description: "The Webpage Cannot Be Found",
          error404BackHomeLinke: "Back To Home",

          //Login
          usernameRequiredMessage: "Please enter your username",
          passwordRequiredMessage: "Please enter your password",
          emailRequiredMessage: "Please enter your email",
          rememberMe: "Remember me",
          forgotPassword: "Forgot Password?",
          signIn: "Sign In",

          //Buttons
          add: "Add",
          save: "Save",
          next: "Next",
          back: "Back",
          cancel: "Cancel",
          finish: "Finish",
          // #endregion
          //General
          edit: "Edit",
          academicDegree: "Academic Degree",
          startMonth: "Start Month",
          startYear: "Start Year",
          onGoing: "On Going",
          endMonth: "End Month",
          endYear: "End Year",
          description: "Description",

          // #endregion
          //#region CRUD Settings
          list: "List of",
          order: "Order",
          isActive: "Active",
          isActiveQuestion: "Is Active?",
          isDeleted: "Is Deleted?",
          isApproved: "Is Approved?",
          action: "Actions",
          yes: "Yes",
          no: "No",
          limit: "Limit",
          code: "Code",
          title: "Title",
          status: "Status",
          duration: "Duration",
          maximumScore: "Maximum Score",
          education: "Education",
          // experience: "Experience",
          dateOfApplication: "Date Of Application",
          otherInfo: "Other Info",
          dateChanged: "Date Changed",
          brandName: "Brand Name",
          modelNumber: "Model Number",
          startDate: "Start Date",
          endDate: "End Date",
          noEndDate: "No End Date",
          startTime: "Start Time",
          endTime: "End Time",
          zipCode: "Zip Code",
          phoneNumber: "Phone Number",
          vendorManagerName: "Vendor Manager Name",
          vendorManagerPhone: "Vendor Manager Phone",
          totalGrade: "Total Grade",
          isPassed: "Is Passed",
          isHired: "Is Hired",
          answerDetails: "Answer Details",
          notes: "Notes",
          details: "Details",
          documentPathName: "Document Path Name",
          documentName: "Document Name",
          warning: "Warning",
          sensorId: "Sensor Id",
          openDoorFlag: "Open Door Flag",
          exported: "Exported",
          checkTime: "Check Time",
          checkDate: "Check Date",
          checked: "Checked",
          findByEmployee: "Find By Employee",
          findByDate: "Find By Date",
          removeFilter: "Remove Filter",
          hours: "Hours",
          assetVendors: "Asset Vendors",
          trainingPlace: "Training Place",
          trainingCost: "Training Cost",
          trainingCompanyName: "Training Company Name",
          isSigned: "Is Signed",
          boundDate: "Bound Date",
          salary: "Salary",
          date: "Date",
          costs: "Costs",
          numberOfDays: "Number of Days",
          accept: "Accept",
          reject: "Reject",
          accepted: "Accepted",
          pending: "Pending",
          pendingApprovals: "Pending Approvals",
          departamentsApprovals: "Departments Approvals",
          rejected: "Rejected",
          clickToEdit: "Click to Edit",
          refresh: "Refresh",
          newest: "Newest",

          // assetVendors: "Asset Vendors",

          onErrorSubmit: "Please fill all the required data",

          createMessageSuccess: "Data has been created!",
          createMessageError: "Something went wrong! - Create!",

          editMessageSuccess: "Data has been edited!",
          editMessageError: "Something went wrong! - Edit!",

          listMessageError: "Something went wrong - List!",

          getMessageError: "Something went wrong - Get!",

          readAPIMessageError: "Something went wrong - API for DropDown!",
          errorMessageError: "Something went wrong!",

          //#region MainSideBar
          dashboard: "Dashboard",
          others: "Others",

          //#region Delete
          deleteTitle: "Are you sure?",
          deleteText: "You won't be able to revert this!",
          deleteConfirmButtonText: "Yes",
          deleteCancelButtonText: "No",
          deleteMessageSuccess: "Data has been deleted!",
          deleteMessageError: "Something went wrong!",
          //#endregion

          //#region DataGrid
          columnMenuUnsort: "Unsort",
          columnMenuSortAsc: "Sort by ASC",
          columnMenuSortDesc: "Sort by DESC",
          columnMenuFilter: "Filter",
          columnMenuHideColumn: "Hide",
          columnMenuShowColumns: "Show columns",
          //#endregion

          //Country
          country: "Country",
          countries: "Countries",
          countryListTitle: "List of Countries",
          countryCreateTitle: "Create Country",
          countryEditTitle: "Edit Country",

          //City
          city: "City",
          cities: "Cities",
          cityListTitle: "List of Cities",
          cityCreateTitle: "Create City",
          cityEditTitle: "Edit City",

          //Role
          role: "Role",
          roles: "Roles",
          roleListTitle: "List of Roles",
          roleCreateTitle: "Create Role",
          roleEditTitle: "Edit Role",
          roleId: "Id",
          roleName: "Name",
          roleDescription: "Description",

          //User
          user: "User",
          users: "Users",
          userListTitle: "List of Users",
          userCreateTitle: "Create Role",
          userEditTitle: "Edit Role",
          userId: "Id",
          userFullName: "Name",
          userEmail: "Email",
          userUsername: "Username",

          //Status
          status: "Status",
          statuses: "Statuses",
          statusListTitle: "List of Statuses",
          statusCreateTitle: "Create Status",
          statusEditTitle: "Edit Status",

          //Motion Type
          motionType: "Motion Type",
          motionTypes: "Motion Types",
          motionTypeListTitle: "List of Motion Types",
          motionTypeCreateTitle: "Create Motion Type",
          motionTypeEditTitle: "Edit Motion Type",

          //Motion
          motion: "Motion",
          motions: "Motions",
          motionListTitle: "List of Motions",
          motionCreateTitle: "Create Motion",
          motionEditTitle: "Edit Motion",
          diaryNumber: "Diary Number",
          diaryDate:"DiaryDate",
          diaryTime:"DiaryTime",
          sponsorPartyID:"Sponsor Party ID",
          description:"Description",
          isSigned:"Is Signed",
          isApproved:"Is Approved",
          document:"Document",


           //Motion Status
           motionStatus: "Motion Status",
           motionStatuses: "Motion Statuses",
           motionStatusListTitle: "List of Motion Statuses",
           motionStatusCreateTitle: "Create Motion Status",
           motionStatusEditTitle: "Edit Motion Status",

           //Question By Chair
           questionByChair: "Question By Chair",
           questionByChairs: "Questions By Chair",
           questionByChairListTitle: "List of Questions By Chair",
           questionByChairCreateTitle: "Create Question By Chair",
           questionByChairEditTitle: "Edit Question By Chair",

           //Motion Mover
           motionMover: "Motion Mover",
           motionMovers: "Motion Movers",
           motionMoverListTitle: "List of Motion Movers",
           motionMoverCreateTitle: "Create Motion Mover",
           motionMoverEditTitle: "Edit Motion Mover",
           movedBy:"Moved By",

          //Industry
          industry: "Industry",
          industries: "Industries",
          industryListTitle: "List of industries",
          industryCreateTitle: "Create Industry",
          industryEditTitle: "Edit Industry",
          addIndustry: " Add Industry",
          industryName: "Industry Name",

          //Company
          company: "Company",
          companies: "Companies",
          companyListTitle: "List of companies",
          companyCreateTitle: "Create Company",
          companyEditTitle: "Edit Company",
          addCompany: " Add Company",
          companyName: "Company Name",
          companyFoundYear: "Company Found Year",

          //Mergers And Acquisitions
          mergeAndAcquisition: "Merge And Acquisition",
          mergersAndAcquisitions: "Mergers And Acquisitions",
          mergeAndAcquisitionListTitle: "List of Mergers And Acquisitions",
          mergeAndAcquisitionCreateTitle: "Create Merge And Acquisition",
          mergeAndAcquisitionEditTitle: "Edit Merge And Acquisition",
          addMergeAndAcquisition: " Add Merge And Acquisition",
          foundCompanyName: "Found Company Name",
          MACompanyName: "M&A by",
          foundCompYear: "Found Company Year",
          MADate: "M&A Date",
          lifetime: "Lifetime",
          infoCompanyFound: "Info. for Company Found",
          infoWhenMerged : "Info. when Merged",
          otherDetails : "Other Details",
          bmFound: "BM Found",
          bmMerged: "BM Merged",
          mergersAndAcquisitionsEditTitle: "Edit Merge And Acquisition ",
          mergersAndAcquisitionsCreateTitle: " Add Merge And Acquisition",
          // companyName: "Company Name",
          // companyFoundYear: "Company Found Year",

          MAndATimelineGraphTimeLine: "Timeline Graph",
          MAndATimelineGraphNetworkGraph: "Network Graph",
          MAndATimelineGraphNSankeyDiagram: "Sankey Diagram",
          
        },
      },
      lng2: {
        translation: {
          // #region General Description
          currentLanguage: "(Urdu)",
          languageLNG1: "(EN)",
          languageLNG2: "(Urdu)",
          languageLNG3: "(Sindi)",

          descriptionLNG1: "(EN)",
          descriptionLNG2: "(Urdu)",
          descriptionLNG3: "(Sindi)",

          systemName: "EDA SYSTEM",

          //#region CRUD Settings

          order: "Order LNG2",

          //#endregion
        },
      },
      lng3: {
        translation: {
          // #region General Description
          currentLanguage: "(EN)",
          languageLNG1: "(EN)",
          languageLNG2: "(Urdu)",
          languageLNG3: "(Sindi)",

          descriptionLNG1: "(EN)",
          descriptionLNG2: "(Urdu)",
          descriptionLNG3: "(Sindi)",

          systemName: "EDA SYSTEM",

          //#region CRUD Settings

          order: "Order LNG3",

          //#endregion
        },
      }
    },
  });
