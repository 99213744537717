import { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import useAuth from '../hooks/useAuth';
import axios from '../api/axios';
import useRefreshToken from '../hooks/useRefreshToken';

const LOGIN_URL = '/Login/Login';
const Login = () => {
    const { t, i18n } = useTranslation();
    const { auth, setAuth, persist, setPersist } = useAuth();
    const refresh = useRefreshToken();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/Dashboard";

    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let isMounted = true;

        const verifyRefreshToken = async () => {
            try {
                await refresh();
            }
            catch (err) {
                //console.error(err);
            }
            finally {
                isMounted && setIsLoading(false);
            }
        }

        !auth?.accessToken && persist ? verifyRefreshToken() : setIsLoading(false);
       
        return () => isMounted = false;
    }, [])

    useEffect(() => {
        if (Object.keys(auth).length > 0) {
            navigate(from, { replace: true });
        }
        else {
            navigate('/');
        }
    }, [auth])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    useEffect(() => {
        localStorage.setItem("persist", persist);
    }, [persist])


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(LOGIN_URL,
                JSON.stringify({ username: user, password: pwd }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );

            setAuth({ user, role: response?.data?.role, accessToken: response?.data?.accessToken });
            setUser('');
            setPwd('');
            i18n.changeLanguage(response?.data?.lang);
            
            navigate(from, { replace: true });


        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }
    }
    const togglePersist = () => {
        setPersist(prev => !prev);
    }


    return (

        <>
            {!isLoading ? (<div className="position-relative overflow-hidden radial-gradient min-vh-100 d-flex align-items-center justify-content-center">
                <div className="col-md-8 col-lg-6 col-xxl-3">
                    <div className="card mb-0">
                        <div className="card-body">
                            <section>
                                <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                                <h3><span className="loginTitle px-2 py-2">{t("login")}</span></h3>
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <label htmlFor="username" className="col-form-label-lg">Username:</label>
                                        <input
                                            type="text"
                                            className="form-control rounded-0"
                                            id="username"
                                            ref={userRef}
                                            autoComplete="off"
                                            onChange={(e) => setUser(e.target.value)}
                                            value={user}
                                            required
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="password" className="col-form-label-lg">Password:</label>
                                        <input
                                            type="password"
                                            className="form-control rounded-0"
                                            id="password"
                                            onChange={(e) => setPwd(e.target.value)}
                                            value={pwd}
                                            autoComplete="new-password"
                                            required
                                        />
                                    </div>
                                    <button
                                        type="submit"
                                        className="btn btn-primary w-75 mx-auto d-block rounded-2">
                                        {t("login")}
                                    </button>
                                    {/* <div className="mb-4">
                                        <div className="persistCheck">
                                            <input
                                                type="checkbox"
                                                id="persist"
                                                onChange={togglePersist}
                                                checked={persist}
                                            />
                                            <label htmlFor="persist" className="col-form-label-lg">Trust This Device</label>
                                        </div>
                                    </div> */}
                                </form>
                            </section>
                        </div>
                    </div>
                </div>
            </div>) : (<div></div>)}
        </>
    )
}

export default Login
