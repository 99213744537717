import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../stores/store';
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useFetchData from '../../../hooks/useFetchData';
import { useSetMenu } from "../../../hooks/useSetMenu";

const MAndATimelineGraph = observer(() => {
  const d3Container = useRef(null);
  const {mainStore,  timeLineStore } = useStore();
  const axiosPrivate = useAxiosPrivate();
  useFetchData(axiosPrivate, "/MergersAndAcquisitions/GetMAndAActivities", timeLineStore);
  useSetMenu(mainStore.MAndATimelineGraphMenuType, mainStore.MAndATimelineGraphMenuNo);
  // Define margins and dimensions outside of useEffect
    // Define margins and dimensions outside of useEffect
    const margin = { top: 20, right: 20, bottom: 40, left: 250 };
    const width = 1500 - margin.left - margin.right;
    const height = 800 - margin.top - margin.bottom; // Initial height
  
    useEffect(() => {
      if (timeLineStore.timeLine.length > 0) {
        // Process your data here
        const data = timeLineStore.timeLine.map(d => ({
          AcquirerName: d.acquirerName,
          TargetName: d.targetName,
          AAndMDate: new Date(d.aAndMDate)
        }));
  
        // Determine the unique company names
        const allCompanyNames = [...new Set(data.flatMap(d => [d.AcquirerName, d.TargetName]))];
  
        // Adjust graph height based on the number of companies
        const itemHeight = 30; // You can increase or decrease this value for spacing
        const graphHeight = itemHeight * allCompanyNames.length;
  
        // Create the SVG canvas
        const svg = d3.select(d3Container.current);
        svg.selectAll("*").remove(); // Clear any existing SVG elements
  
        // Set up scales
        const xScale = d3.scaleTime()
          .domain(d3.extent(data, d => d.AAndMDate))
          .range([0, width]);
  
        const yScale = d3.scaleBand()
          .domain(allCompanyNames)
          .range([0, graphHeight])
          .padding(0.1); // Adjust the padding to increase spacing
  
        const canvas = svg.append('g')
          .attr('transform', `translate(${margin.left},${margin.top})`);
  
        // Add the X Axis with all years
        const years = Array.from(new Set(data.map(d => d.AAndMDate.getFullYear())));
        const xAxis = d3.axisBottom(xScale)
          .tickValues(years.map(year => new Date(year, 0, 1)))
          .tickFormat(d3.timeFormat("%Y"));
  
        canvas.append('g')
          .attr('class', 'x-axis')
          .attr('transform', `translate(0,${graphHeight})`)
          .call(xAxis);
  
        // Add the Y Axis with rotated labels for readability
        canvas.append('g')
          .call(d3.axisLeft(yScale))
          .selectAll("text")
          .style("text-anchor", "end")
          .attr("dx", "-0.8em")
          .attr("dy", "0.15em")
          .attr("transform", "rotate(-40)"); // Rotate labels for better spacing
  
        // Tooltip div
        const tooltip = d3.select("body").append("div")
          .attr("class", "tooltip")
          .style("opacity", 0);
  
        // Draw the lines for each M&A event
        canvas.selectAll(".mandaline")
          .data(data)
          .join("line")
          .attr("class", "mandaline")
          .attr("x1", d => xScale(d.AAndMDate))
          .attr("y1", d => yScale(d.AcquirerName) + yScale.bandwidth() / 2)
          .attr("x2", d => xScale(d.AAndMDate))
          .attr("y2", d => yScale(d.TargetName) + yScale.bandwidth() / 2)
          .attr("stroke", "blue")
          .attr("stroke-width", 2)
          .on("mouseover", (event, d) => {
            tooltip.transition()
              .duration(200)
              .style("opacity", 0.9);
            tooltip.html(`Acquirer: ${d.AcquirerName}<br/>Target: ${d.TargetName}<br/>Date: ${d3.timeFormat("%B %d, %Y")(d.AAndMDate)}`)
              .style("left", (event.pageX) + "px")
              .style("top", (event.pageY - 28) + "px");
          })
          .on("mouseout", () => {
            tooltip.transition()
              .duration(500)
              .style("opacity", 0);
          });
  
        // Set the viewBox to enable the entire graph to scale correctly on zoom
        svg.attr('viewBox', `0 0 ${width + margin.left + margin.right} ${graphHeight + margin.top + margin.bottom}`);
      }
    }, [timeLineStore.timeLine]);
  
    return (
        <div>
      <div className="graph-description">
        <p>
          This timeline graph offers a visual representation of mergers and acquisitions (M&A) activity across the Electronic Design Automation (EDA) industry over time. Each line on the graph denotes a specific M&A event, connecting the acquirer and the target company at the precise year of the transaction. The y-axis enumerates the entities involved, while the x-axis is temporally oriented, displaying the chronological sequence of events.
        </p>
        <p>
          The purpose of this visualization is to facilitate a historical analysis of industry consolidation trends and to identify periods of heightened activity which may correlate with technological disruptions or market shifts. By examining the aggregation patterns, we can infer the strategic business movements within the EDA sector, such as diversification or specialization trends, and the consequent changes in the competitive landscape.
        </p>
        <p>
          Furthermore, the zoom and pan features embedded in the graph allow for an interactive exploration of the data, offering the ability to focus on specific time frames or M&A events in detail. This interactivity is instrumental in managing the dense dataset effectively, enabling a more focused and user-driven approach to data analysis.
        </p>
      </div>
      <div style={{ width: '100%', height: '800px', overflowY: 'scroll' }}>
        <svg
          className="d3-component"
          ref={d3Container}
          // viewBox is set dynamically in the useEffect hook
        />
      </div>
    </div>
    );
  });
  
  export default MAndATimelineGraph;