import { makeAutoObservable, runInAction } from "mobx";

export default class companyStore {
  company = [];

  constructor() {
    makeAutoObservable(this);
  }

  setData = (data) => {
    this.company = [];
    runInAction(() => {
      this.company = data;
    });
  };

  deleteCompany = (id) => {
    let newState = this.company.filter((company) => company.id !== id);
    this.company = [];
    this.company = newState;
  };
}
