import { makeAutoObservable } from "mobx";
import { create, persist } from "mobx-persist";
import { observable, action } from "mobx";
import CryptoJS from 'crypto-js';

const SECRET_KEY = 'Naim^^Elton^^Lavdim^^Blerim^^LlegendaTegjalla!!@@##$$%%^^'; // Remember to keep this secret!

// const encryptedLocalStorage = {
//   setItem: (key, value) => {
//       const encryptedValue = CryptoJS.AES.encrypt(JSON.stringify(value), SECRET_KEY).toString();
//       return localStorage.setItem(key, encryptedValue);
//   },

//   getItem: key => {
//       const encryptedValue = localStorage.getItem(key);
//       if (encryptedValue) {
//           const bytes = CryptoJS.AES.decrypt(encryptedValue, SECRET_KEY);
//           const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
//           return decryptedData;
//       }
//       return null;
//   },

//   removeItem: key => localStorage.removeItem(key),
//   clear: () => localStorage.clear(),
// };
const encryptedLocalStorage = {
  setItem: (key, value) => {
    return new Promise((resolve, reject) => {
      try {
        const encryptedValue = CryptoJS.AES.encrypt(JSON.stringify(value), SECRET_KEY).toString();
        localStorage.setItem(key, encryptedValue);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  },

  getItem: key => {
    return new Promise((resolve, reject) => {
      try {
        const encryptedValue = localStorage.getItem(key);
        if (!encryptedValue) {
          return resolve(null);
        }
        const bytes = CryptoJS.AES.decrypt(encryptedValue, SECRET_KEY);
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        resolve(decryptedData);
      } catch (error) {
        reject(error);
      }
    });
  },

  // similarly for removeItem and clear
  removeItem: key => localStorage.removeItem(key),
  clear: () => {
    return new Promise((resolve, reject) => {
      try {
        localStorage.clear();
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }
};


const hydrate = create({
  storage: encryptedLocalStorage,
  jsonify: false,
});

export default class MainStore {
  @persist @observable token = null;
  @persist @observable role = null;
  @persist @observable username = null;
  @persist @observable lang = null;
  menuOpened = false;
  showSignOut = false;

  menuNo = 1;
  menuType = 0;
  menuToggle = 0;
  memberTabValue = "1";


  setToken = (tk) => {
    this.token = tk;
  };
  setUsername = (tk) => {
    this.username = tk;
  };
  setLang = (l) => {
    this.lang = l;
  };
  setRole = (l) => {
    this.role = l;
  };
  // clearStorage() {
  //   encryptedLocalStorage.clear();
  //   // this.token = null;
  //   // this.username = null;
  //   // this.role = null;
  //   // this.lang = null;
  // }
  @action clearStorage() {

    encryptedLocalStorage.clear()
      .then(() => {
        // console.log("Encrypted localStorage has been cleared");
      })
      .catch((error) => {
        console.error("Failed to clear encrypted localStorage:", error);
      });
    this.token = null;
    this.username = null;
    this.role = null;
    this.lang = null;
  }

  errorStatus4xx = [
    400, 401, 402, 403, 404, 405, 406, 407, 408, 409, 410, 411, 412, 413, 414,
    415, 416, 417, 418, 421, 422, 423, 424, 425, 426, 428, 429, 431, 451,
  ];
  deletedSuccessfully = 0;
  // leftCssConent = "col-md-2 d-sm-none d-md-block";
  leftCssConent = "col-sm-12 col-md-3 col-lg-2 col-xl-2";
  rightCssConent = "col-sm-12 col-md-9 col-lg-10 col-xl-10";
  rightCssConent2 = "col-md-12";
  rightCssConentFullWidth = "col-md-12";

  systemDomain = process.env.REACT_APP_SERVER_URL;

  

  constructor() {
    makeAutoObservable(this, {
      setToken: action,
    });
    hydrate("rootStore", this);

  }

  CountryMenuType = 100;
  CountryMenuNo = 101;

  CityMenuType = 100;
  CityMenuNo = 102;

  StatusMenuType = 100;
  StatusMenuNo = 103;

  MotionTypeMenuType = 100;
  MotionTypeMenuNo = 104;

  MotionMenuType = 100;
  MotionMenuNo = 105;

  MotionStatusMenuType = 100;
  MotionStatusMenuNo = 106;

  QuestionByChairMenuType = 100;
  QuestionByChairMenuNo = 107;

  MotionMoverMenuType = 100;
  MotionMoverMenuNo = 108;
  
  IndustryMenuType = 100;
  IndustryMenuNo = 109;

  CompanyMenuType = 100;
  CompanyMenuNo = 110;

  
  MergerAndAcquisitionMenuType = 100;
  MergerAndAcquisitionMenuNo = 111;

  MAndATimelineGraphMenuType = 100;
  MAndATimelineGraphMenuNo = 112;

  NetworkGraphMenuType = 100;
  NetworkGraphMenuNo = 113;
  
  SankeyDiagramMenuType = 100;
  SankeyDiagramMenuNo = 114;
  
  
  setMenuType = (tk) => {
    this.menuType = tk;
    if (tk === 0) {
      this.rightCssConent = "col-md-12";
    } else {
      // this.rightCssConent ="col-sm-12 col-md-10";
      this.rightCssConent = "col-sm-12 col-md-9 col-lg-10 col-xl-10";
    }
  };

  setDeletedSuccessfully = () => {
    this.deletedSuccessfully += 1;
  };


  setMenuNo = (tk) => {
    this.menuNo = tk;
    //  this.menuToggle = 0;
    this.setMenuToggleNo(0);
  };

  setMenuToggleNo = (tk) => {
    this.menuToggle = tk;
  };
  setMenuOpen = (v) => {
    this.menuOpened = v;
  };

  setSignOutModal = (s) => {
    this.showSignOut = s;
  };

  setIsMobileMenuOpen = (param) => {
    if (param === 0 && this.isMobileMenuOpen) {
      document.body.classList.remove('g-sidenav-pinned');
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    }
    else if (param === 1) {
      if (this.isMobileMenuOpen) {
        document.body.classList.remove('g-sidenav-pinned');
      }
      else {
        const bodyClasses = document.body.classList;
        bodyClasses.add('g-sidenav-pinned');
        bodyClasses.forEach(className => {
          document.body.classList.add(className);

        });
      }
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    }

  };

  // setUser = (u) => {
  //   this.loggedUser = u;
  // };
  // getUser = () => {
  //   return this.loggedUser;
  // };

  // setIsLogged = (l) => {
  //   this.isLogged = l;
  // };
  setMemberTabValue = (l) => {
    this.memberTabValue = l;
  };
  setComponentTitle = (param) => {
    this.componentTitle = param;
  };
}
