import { Link } from 'react-router-dom';
import { format } from "date-fns";

export const createLanguageColumn = (field, headerName, language, t, minWidth=170) => ({
    field: field,
    headerName: t(headerName) + " " + t(language),
    flex: 1,
    minWidth: minWidth,
    headerClassName: 'tableHeaderClassName',
    cellClassName: 'tableCellClassName',
});

export const createOrderColumn = (t,minWidth=140) => ({
    field: "orderNo",
    headerName: t("order"),
    flex: 0.3,
    minWidth: minWidth,
    headerClassName: 'tableHeaderClassName',
    cellClassName: 'tableCellClassName',
    headerAlign: "center",
    align: "center",
});


export const createActiveColumn = (t,minWidth=140) => ({
    field: "active",
    headerName: t("isActive"),
    sortable: false,
    flex: 0.3,
    minWidth: minWidth,
    headerAlign: "center",
    align: "center",
    headerClassName: 'tableHeaderClassName',
    cellClassName: 'tableCellClassName',
    renderCell: (params) => params.row.isActive 
        ? <i className="bi bi-eye-fill text-success me-1"></i> 
        : <i className="bi bi-eye-slash-fill text-danger me-1"></i>,
});

export const createActionColumn = (t, editPath, removeFn,minWidth=150) => ({
    field: "action",
    headerName: t("action"),
    sortable: false,
    flex: 0.5,
    minWidth: minWidth,
    headerAlign: "center",
    align: "center",
    headerClassName: 'tableHeaderClassName',
    cellClassName: 'tableCellClassName',
    renderCell: (params) => (
        <>
            <Link to={`${editPath}/${params.row.id}`}>
                <i className="bi bi-pencil text-success me-3"></i>
            </Link>
            <div
                onClick={() => removeFn(params.row.id)}
                style={{ cursor: "pointer" }}
            >
                <i className="bi bi-trash-fill text-danger"></i>
            </div>
        </>
    ),
});

export const createGeneralColumn = (field,headerName,t,minWidth=150) => ({
    field: field,
    headerName: t(headerName),
    flex: 1,
    minWidth: minWidth,
    headerClassName: 'tableHeaderClassName',
    cellClassName: 'tableCellClassName',
   
});
export const createDateColumn = (field, headerName, t, minWidth = 100) => ({
    field: field,
    headerName: t(headerName),
    flex: 1,
    minWidth: minWidth,
    headerClassName: 'tableHeaderClassName',
    cellClassName: 'tableCellClassName',
    valueFormatter: (params) => {
        // Format the date as yyyy-MM-dd
        const date = new Date(params.value);
        return format(date, "yyyy-MM-dd");
        // return date.toISOString().split('T')[0];
    },
});

export const createTimeColumn = (field, headerName, t, minWidth = 100) => ({
    field: field,
    headerName: t(headerName),
    flex: 1,
    minWidth: minWidth,
    headerClassName: 'tableHeaderClassName',
    cellClassName: 'tableCellClassName',
    valueFormatter: (params) => {
        // Format the date as yyyy-MM-dd
        const date = new Date(params.value);
        return format(date, "h:mm:ss");
        // return date.toISOString().split('T')[0];
    },
});

export const createBoolColumn = (field,headerName,t,minWidth=140) => ({
    field: field,
    headerName: t(headerName),
    sortable: false,
    flex: 0.3,
    minWidth: minWidth,
    headerAlign: "center",
    align: "center",
    headerClassName: 'tableHeaderClassName',
    cellClassName: 'tableCellClassName',
    renderCell: (params) => params.row[field] 
    ? <i class="bi bi-check-circle-fill text-success"></i>
    : <i className="bi bi-x-circle-fill text-danger"></i>,
});

export const createDocumentColumn = (field,headerName,t,mainStore,minWidth=150) => ({
    field: field,
    headerName: t(headerName),
    flex: 1,
    minWidth: minWidth,
    headerClassName: 'tableHeaderClassName',
    cellClassName: 'tableCellClassName',
    renderCell: (params) => {
        return (
          <a
            href={mainStore.systemDomain + params.row.filePath}
            download
            target="_blank"
            rel="noopener noreferrer"
            style={{ cursor: "pointer" }}
            className="d-flex">
            <i className="bi bi-file-earmark-fill text-info mx-1"></i>
            <div>{params.row.fileName}</div>
          </a>
        );
      },
});
// ... more common column definitions
