import React from 'react';
import { DataGrid } from '@mui/x-data-grid';

const DataTable = ({ columns, rows, pageSize, setPageSize, localeTextsMap }) => {
  return (
    <DataGrid
      autoHeight
      rows={rows}
      columns={columns}
      pageSize={pageSize}
      onPageSizeChange={setPageSize}
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      localeText={localeTextsMap}
      style={{ overflowX: 'auto' }}
    />
  );
};

export default DataTable;
