import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../../stores/store';
import ListLayout from '../../Generic/ListLayout';
import DataTable from '../../Generic/DataTable';
import confirmDeleteDialog from '../../Generic/confirmDeleteDialog';
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useDeleteItem from '../../../hooks/useDeleteItem';
import useFilterData from '../../../hooks/useFilterData';
import useFetchData from '../../../hooks/useFetchData';
import useLocalizedTextsMap from '../../../hooks/useLocalizedTextsMap';
import { useSetMenu } from "../../../hooks/useSetMenu";
import {createGeneralColumn} from '../../Generic/dataGridColumns';
import {createActiveColumn, createActionColumn } from '../../Generic/dataGridColumns';

const List = () => {
  const axiosPrivate = useAxiosPrivate();
  const { mainStore, industryStore } = useStore();
  const { t } = useTranslation();
  const localizedTextsMap = useLocalizedTextsMap();
  useSetMenu(mainStore.IndustryMenuType, mainStore.IndustryMenuNo);
  const [columns, setColumns] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");

  // FetchData and Search
  useFetchData(axiosPrivate, "/Industry", industryStore);
  const filteredData = useFilterData(toJS(industryStore.industry), searchQuery);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Delete
  const deleteIndustry = useDeleteItem(axiosPrivate, '/Industry', industryStore.deleteIndustry);

  const remove = useCallback((id) => {
    confirmDeleteDialog(t, deleteIndustry, id);
  }, [deleteIndustry, t]);

  const cols = useMemo(() => [
    createGeneralColumn("industryName", "industry", t),
    createActionColumn(t, "/Industry/Edit", remove),
  ], [t, remove]);

  useEffect(() => {
    setColumns(cols);
    // eslint-disable-next-line
  }, [t]);

  return (
    <ListLayout
      title={t("industryListTitle")}
      searchQuery={searchQuery}
      onSearchChange={handleSearchChange}
      searchPlaceholder={t("searchPlaceholder")}
      addButtonLink="/Industry/Add"
      addButtonTitle={t("addIndustry")}
    >
      <DataTable
        rows={filteredData}
        columns={columns}
        pageSize={pageSize}
        setPageSize={setPageSize}
        localeTextsMap={localizedTextsMap}
      />
    </ListLayout>
  );
};

export default observer(List);