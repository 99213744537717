// 

import React from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useStore } from "../../../stores/store";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useSubmitData } from "../../../hooks/useSubmitData";
import { useFormSchema } from "../../../hooks/useFormSchema";
import { useSetMenu } from "../../../hooks/useSetMenu";
import FormLayout from '../../Generic/FormLayout';
import FormInput from '../../Generic/FormInput';
import * as yup from "yup";
const Add = () => {
  
  const { mainStore } = useStore();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  useSetMenu(mainStore.IndustryMenuType, mainStore.IndustryMenuNo);
  const onError = (errors, e) =>
    enqueueSnackbar(t("onErrorSubmit"), { variant: "error" });
  // const schema = useFormSchema();
  const schema = yup.object({
    industryName: yup
      .string()
      .required(t('requiredField'))
      .max(200, t('maxLength', { max: 200 })),
   
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const sendData = useSubmitData(axiosPrivate, "/Industry", "/Industry/List");
  const onSubmit = data => sendData(data);
  const onCancel = () => navigate(-1);

  return (
    <FormLayout title={t("industryCreateTitle")} onSubmit={handleSubmit(onSubmit, onError)} onCancel={onCancel}>
      <FormInput label={t("industryName")} name="industryName" register={register} errors={errors} />
    </FormLayout>
  );
};

export default Add;