import { useTranslation } from 'react-i18next';

const FormInput = ({ label, register, name, errors, type = "text", language, colSize = 4, required = true, step = "any" }) => {
    const { t } = useTranslation();
    const colClass = `col-lg-${colSize} mb-3`;
    return (
        <div className={colClass}>
            <div className="form-group">
                <label className="labelDescription">
                    <span className="labelText">
                        {t(label)} {language && <span>{t(language)}</span>}
                    </span>
                    {required && <span className="labelStar text-danger fw-bold">*</span>}
                </label>
                <input
                    type={type}
                    step={step}
                    className={`form-control rounded-0 ${errors[name] ? "requiredInput" : ""}`}
                    {...register(name)}
                />
                {errors[name] && (
                    <div className="error-message text-danger">
                        {errors[name].message}
                    </div>
                )}
            </div>
        </div>
    );
};

export default FormInput;
