import { useState, useEffect } from 'react';

const useFetchData = (axiosInstance, endpoint, storeUpdateFn) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(endpoint, { signal });
      console.log(response);

        if (response.data) {
          storeUpdateFn.setData(response.data);
          setData(response.data);
        }
      } catch (error) {
        if (error?.request?.status !== 0) {
          console.log(error);
        }
      }
    };

    fetchData();
    return () => {
      controller.abort();
    };
  }, [axiosInstance, endpoint, storeUpdateFn]);

  return data;
};

export default useFetchData;
