import { observer } from "mobx-react-lite";
import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useStore } from "../../stores/store";
import useLogout from "../../hooks/useLogout";
import AuthContext from "../../context/AuthProvider";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const Header = () => {
    const { t, i18n } = useTranslation();
    const { mainStore } = useStore();
    let navigate = useNavigate();
    const location = useLocation();
    const logout = useLogout();

    const { auth } = useContext(AuthContext);
    const axiosPrivate = useAxiosPrivate();


    const signOut = async () => {
        await logout();
        navigate('/');
    };


    const setLanguage = async (lang) => {
        try {
            await axiosPrivate.get(`/Home/SetLanguage/${lang}`);
            i18n.changeLanguage(lang);
        } catch (err) {
            console.error(err);
            navigate('/', { state: { from: location }, replace: true });

        }
    };


    const handleClick = async () => {
        mainStore.setIsMobileMenuOpen(1);
    }


    return (
        <nav
            className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl "
            id="navbarBlur"
            navbar-scroll="true"
        >
            <div className="container-fluid py-1 px-3 ">
                <div
                    className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4 justify-content-end"
                    id="navbar"
                >
                    <ul className="navbar-nav justify-content-end">
                        <li className="nav-item d-xl-none ps-3 pe-2 d-flex align-items-center">
                            <a

                                className="nav-link text-body p-0"
                                id="iconNavbarSidenav"
                                onClick={handleClick}
                            >
                                <div className="sidenav-toggler-inner">
                                    <i className="sidenav-toggler-line"></i>
                                    <i className="sidenav-toggler-line"></i>
                                    <i className="sidenav-toggler-line"></i>
                                </div>
                            </a>

                        </li>
                        <li className="nav-item dropdown px-2  pe-3 d-flex align-items-center">
                            <a
                                href="#!"
                                className="nav-link text-body p-0"
                                id="dropdownMenuButton"
                                role="button"
                                data-bs-toggle="dropdown"
                            >
                                <span className="font-weight-bold">{t("currentLanguage")}</span>
                            </a>
                            <ul
                                className="dropdown-menu  dropdown-menu-end  px-2 py-3 me-sm-n4"
                                aria-labelledby="dropdownMenuButton"
                            >
                                <li className="mb-0">
                                    <a
                                        className={`dropdown-item border-radius-md ${mainStore.lang === "lng1" ? "active" : ""
                                            }`}
                                        onClick={() => setLanguage("lng1")}
                                    >
                                        <div className="d-flex ">{t("languageLNG1")}</div>
                                    </a>
                                </li>
                                {/* <li className="mb-0">
                                    <a
                                        className={`dropdown-item border-radius-md ${mainStore.lang === "lng2" ? "active" : ""
                                            }`}
                                        onClick={() => setLanguage("lng2")}
                                    >
                                        <div className="d-flex ">{t("languageLNG2")}</div>
                                    </a>
                                </li> */}
                                {/* <li className="mb-0">
                                    <a
                                        className={`dropdown-item border-radius-md ${mainStore.lang === "lng3" ? "active" : ""
                                            }`}
                                        onClick={() => setLanguage("lng3")}
                                    >
                                        <div className="d-flex ">{t("languageLNG3")}</div>
                                    </a>
                                </li> */}
                            </ul>
                        </li>
                        {auth?.user? (
                            <>
                                {auth?.role == "admin" && (
                                    <>
                                        <li className="nav-item px-2 d-flex align-items-center">
                                            <Link
                                                to="/Settings/Dashboard"
                                                className={`nav-link text-body p-0 ${mainStore.menuType === 6 ? "active" : ""
                                                    }`}
                                            >
                                                <i className="bi bi-gear-fill cursor-pointer"></i>
                                            </Link>
                                        </li>

                                        <li className="nav-item px-2 d-flex align-items-center">
                                            <Link
                                                to="/ActivityLogList"
                                                className={`nav-link text-body p-0 ${mainStore.menuType === 6 ? "active" : ""
                                                    }`}
                                            >
                                                <i
                                                    className="bi bi-file-earmark-fill cursor-pointer"
                                                    aria-hidden="true"
                                                ></i>
                                            </Link>
                                        </li>
                                    </>
                                )}
                                <li className="nav-item dropdown px-2 ps-3 d-flex align-items-center">
                                    <a
                                        href="#"
                                        className="nav-link text-body p-0"
                                        id="dropdownMenuButton"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <img
                                            className="profileImg"
                                            width={30}
                                            height={30}
                                            src={"/assets/img/default_profile.png"}
                                            alt="profilePic"
                                        />
                                    </a>
                                    <ul
                                        className="dropdown-menu  dropdown-menu-end  px-2 py-3 me-sm-n4"
                                        aria-labelledby="dropdownMenuButton"
                                    >
                                        <li className="mb-1">
                                            <div className="d-flex flex-row dropdown-item">
                                                <div className="myProfileImage pe-2">
                                                    <img
                                                        className="profileImg"
                                                        width={30}
                                                        height={30}
                                                        src={"/assets/img/default_profile.png"}
                                                        alt="profilePic"
                                                    />
                                                </div>
                                                <div className="d-flex flex-column justify-content-center">
                                                    <span className="profileName">
                                                        Username : {auth?.user}
                                                    </span>
                                                    <span className="profilePosition">
                                                        Role : {auth?.role}
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <div className="dropdown-divider"></div>
                                        <li className="mb-1">
                                            <Link
                                                to={"/ChangePassword/"}
                                                className={`dropdown-item ${mainStore.menuType === 123 ? "active" : ""
                                                    }`}
                                            >
                                                <i className="bi bi-shield-lock-fill me-2" aria-hidden="true"></i>{" "}
                                                {t("topHeaderChangePassword")}
                                            </Link>
                                        </li>
                                        <li className="mb-1">
                                            <Link
                                                to="#"
                                                className={`dropdown-item ${mainStore.menuType === 123 ? "active" : ""
                                                    }`}
                                            >
                                                <i className="bi bi-gear-fill me-2" aria-hidden="true"></i>{" "}
                                                {t("topHeaderSettings")}
                                            </Link>
                                        </li>
                                        {auth?.role == "admin" && (
                                            <li className="mb-1">
                                                <Link
                                                    to="/ActivityLogList"
                                                    className={`dropdown-item ${mainStore.menuType === 123 ? "active" : ""
                                                        }`}
                                                >
                                                    <i
                                                        className="bi bi-clock-fill me-2"
                                                        aria-hidden="true"
                                                    ></i>{" "}
                                                    {t("activityLog")}
                                                </Link>
                                            </li>
                                        )}
                                        <div className="dropdown-divider"></div>
                                        <li className="mb-1">
                                            <Link
                                                to="#"
                                                className={`dropdown-item ${mainStore.menuType === 123 ? "active" : ""
                                                    }`}
                                            >
                                                <i
                                                    className="bi bi-info-circle-fill me-2"
                                                    aria-hidden="true"
                                                ></i>{" "}
                                                {t("help")}
                                            </Link>
                                        </li>
                                        <li className="mb-1">
                                            <Link
                                                to="#"
                                                onClick={() => signOut()}
                                                className={`dropdown-item ${mainStore.menuType === 123 ? "active" : ""
                                                    }`}
                                            >
                                                <i
                                                    className="bi bi-arrow-right-square-fill me-2"
                                                    aria-hidden="true"
                                                ></i>{" "}
                                                {t("logout")}
                                            </Link>
                                        </li>
                                    </ul>
                                </li>

                            </>
                        ) : (
                            <Link to="/Login" className="nav-link text-body p-0 px-1">
                                <span className="font-weight-bold"> {t("topHeaderLogin")}</span>
                            </Link>
                        )}
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default observer(Header);
