import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../../stores/store';
import ListLayout from '../../Generic/ListLayout';
import DataTable from '../../Generic/DataTable';
import confirmDeleteDialog from '../../Generic/confirmDeleteDialog';
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useDeleteItem from '../../../hooks/useDeleteItem';
import useFilterData from '../../../hooks/useFilterData';
import useFetchData from '../../../hooks/useFetchData';
import { useSetMenu } from "../../../hooks/useSetMenu";
import { createGeneralColumn, createActionColumn } from '../../Generic/dataGridColumns';

const List = () => {
  const axiosPrivate = useAxiosPrivate();
  const { mainStore, mergerAndAcquisitionStore } = useStore();
  const { t } = useTranslation();

  const [columns, setColumns] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  useSetMenu(mainStore.MergerAndAcquisitionMenuType, mainStore.MergerAndAcquisitionMenuNo);
  
  // Fetch Data and Search
  useFetchData(axiosPrivate, "/MergersAndAcquisitions", mergerAndAcquisitionStore);
  const filteredData = useFilterData(toJS(mergerAndAcquisitionStore.mergerAndAcquisition), searchQuery);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Delete
  const deleteMergersAndAcquisition = useDeleteItem(axiosPrivate, '/MergersAndAcquisitions', mergerAndAcquisitionStore.deletemergerAndAcquisition);

  const remove = useCallback((id) => {
    confirmDeleteDialog(t, deleteMergersAndAcquisition, id);
  }, [deleteMergersAndAcquisition, t]);

  const formatDate = (dateString) => {
    if (!dateString) return '';
  
    // Create a new Date object using the date string from your data
    const date = new Date(dateString);
    // Format the date as MM/DD/YYYY
    let day = date.getDate().toString().padStart(2, '0');
    let month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is zero-indexed
    let year = date.getFullYear();
  
    return `${month}/${day}/${year}`;
  };
  
  const cols = useMemo(() => [
      createGeneralColumn("companyName1", t("foundCompanyName"), t),
      createGeneralColumn("companyName2", t("MACompanyName"), t),
      {
        ...createGeneralColumn("foundCompYear", t("foundCompYear"), t),
        valueFormatter: ({ value }) => formatDate(value), // Format the date before displaying
      },
      {
        ...createGeneralColumn("aAndMDate", t("MADate"), t),
        valueFormatter: ({ value }) => formatDate(value), // Format the date before displaying
      },
      createGeneralColumn("lifetime", t("lifetime"), t),
      createActionColumn(t, "/MergeAndAcquisition/Edit", remove),
  ], [t, remove]);

  useEffect(() => {
    setColumns(cols);
  }, [t]);

  return (
    <ListLayout
      title={t("mergeAndAcquisitionListTitle")}
      searchQuery={searchQuery}
      onSearchChange={handleSearchChange}
      searchPlaceholder={t("searchPlaceholder")}
      addButtonLink="/MergeAndAcquisition/Add"
      addButtonTitle={t("addMergeAndAcquisition")}
    >
      <DataTable
        rows={filteredData}
        columns={columns}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </ListLayout>
  );
};

export default observer(List);
