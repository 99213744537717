import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useStore } from "../stores/store";
import { useTranslation } from "react-i18next";
const NotFound = () => {
  const { mainStore } = useStore();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    mainStore.setMenuType(-1);
    mainStore.setMenuNo(-1);
  }, []);
  return (
    <div className="col-md-12 notFound">
      <div className="row">
        <div className="col-md-12 d-flex flex-column justify-content-center align-items-center">
          <p className="title404 mt-5 pt-5">{t("error404Title")}</p>
          <p className="desc404">{t("error404Description")}</p>
          <img
            src={"/assets/img/404Error.png"}
            alt="error"
            style={{ width: `20%` }}
          />
          <Link to="/Dashboard" className="link404 mt-5 mb-5">
            {t("error404BackHomeLinke")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
