import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useSubmitData } from "../../../hooks/useSubmitData";
import FormLayout from '../../Generic/FormLayout';
import FormInput from '../../Generic/FormInput';
import FormSelect from '../../Generic/FormSelect';
import { useStore } from "../../../stores/store";
import { useSetMenu } from "../../../hooks/useSetMenu";

const Add = () => {
  const navigate = useNavigate();
  const { mainStore } = useStore();
  const axiosPrivate = useAxiosPrivate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  useSetMenu(mainStore.CompanyMenuType, mainStore.CompanyMenuNo);

  const onError = (errors, e) => enqueueSnackbar(t("onErrorSubmit"), { variant: "error" });

  const schema = yup.object({
    foundCompName: yup
      .string()
      .required(t('requiredField'))
      .max(200, t('maxLength', { max: 200 })),
      foundCompYear: yup
      .number()
      .required(t('requiredField'))
      .positive(t('positiveNumber'))
      .integer(t('integerNumber'))
      .typeError(t('validNumber')),
    industryId: yup.string().required(t("selectRequired")),
  });

  const { register, handleSubmit, formState: { errors }, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  const sendData = useSubmitData(axiosPrivate, "/Company", "/Company/List");
  const onSubmit = data => sendData(data);
  const onCancel = () => navigate(-1);

  // Load Industry Select Options
  const [industries, setIndustries] = useState([]);
  useEffect(() => {
    const loadIndustries = async () => {
      try {
        const response = await axiosPrivate.get("/Industry/SelectOptions");
        setIndustries(response.data);
      } catch (err) {
        enqueueSnackbar(t("loadDataError"), { variant: "error" });
      }
    };
    loadIndustries();
  }, [axiosPrivate, enqueueSnackbar, t]);

  const handleIndustryChange = selectedOption => {
    setValue("industryId", selectedOption.value, { shouldValidate: true });
  };

  return (
    <FormLayout title={t("companyCreateTitle")} onSubmit={handleSubmit(onSubmit, onError)} onCancel={onCancel}>
      <FormInput label={t("companyName")} name="foundCompName" register={register} errors={errors} />
      <FormInput label={t("companyFoundYear")} name="foundCompYear" register={register} errors={errors} type="number" />
      <FormSelect label={t("industry")} name="industryId" register={register} errors={errors} options={industries} onChange={handleIndustryChange} />
    
    </FormLayout>
  );
};

export default Add;
