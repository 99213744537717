import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../../stores/store';
import ListLayout from '../../Generic/ListLayout';
import DataTable from '../../Generic/DataTable';
import confirmDeleteDialog from '../../Generic/confirmDeleteDialog';
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useDeleteItem from '../../../hooks/useDeleteItem';
import useFilterData from '../../../hooks/useFilterData';
import useFetchData from '../../../hooks/useFetchData';
import { useSetMenu } from "../../../hooks/useSetMenu";
import { createGeneralColumn, createActionColumn } from '../../Generic/dataGridColumns';

const List = () => {
  const axiosPrivate = useAxiosPrivate();
  const { mainStore, companyStore } = useStore();
  const { t } = useTranslation();

  const [columns, setColumns] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  useSetMenu(mainStore.CompanyMenuType, mainStore.CompanyMenuNo);
  
  // Fetch Data and Search
  useFetchData(axiosPrivate, "/Company", companyStore);
  const filteredData = useFilterData(toJS(companyStore.company), searchQuery);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Delete
  const deleteCompany = useDeleteItem(axiosPrivate, '/Company', companyStore.deleteCompany);

  const remove = useCallback((id) => {
    confirmDeleteDialog(t, deleteCompany, id);
  }, [deleteCompany, t]);

  const cols = useMemo(() => [
    createGeneralColumn("foundCompName", t("companyName"), t),
    createGeneralColumn("foundCompYear", t("companyFoundYear"), t),
    createGeneralColumn("industryName", "industry", t),
    createActionColumn(t, "/Company/Edit", remove),
  ], [t, remove]);

  useEffect(() => {
    setColumns(cols);
  }, [t]);

  return (
    <ListLayout
      title={t("companyListTitle")}
      searchQuery={searchQuery}
      onSearchChange={handleSearchChange}
      searchPlaceholder={t("searchPlaceholder")}
      addButtonLink="/Company/Add"
      addButtonTitle={t("addCompany")}
    >
      <DataTable
        rows={filteredData}
        columns={columns}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </ListLayout>
  );
};

export default observer(List);
