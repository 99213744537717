import React, { useEffect } from 'react'
import { useStore } from "../../../stores/store";
import { useTranslation } from "react-i18next";


function Dashboard() {
    const { mainStore } = useStore();
    const { t } = useTranslation();

  
    useEffect(() => {
        mainStore.setMenuType(mainStore.DashboardSettingsMenuType);
        mainStore.setMenuNo(mainStore.DashboardSettingsMenuNo);
      }, []);

  return (
    <div className="container">
        <div className="row  d-flex justify-content-center">
            <h1>Settings Dashboard</h1>
        </div>
    </div>
  )
}

export default Dashboard