import { createContext, useContext } from "react";
import MainStore from "./mainStore";

import industryStore from "./industryStore";
import companyStore from "./companyStore";
import mergerAndAcquisitionStore from "./mergerAndAcquisitionStore";
import timeLineStore from "./timeLineStore";

export const store = {
  mainStore: new MainStore(),
 
  industryStore: new industryStore(),
  companyStore: new companyStore(),
  mergerAndAcquisitionStore: new mergerAndAcquisitionStore(),
  timeLineStore: new timeLineStore(),
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
