import Swal from "sweetalert2";

const confirmDeleteDialog = async (t, removeFn, id) => {
  const result = await Swal.fire({
    showCloseButton: true,
    showCancelButton: true,
    allowEscapeKey: true,
    title: t("deleteTitle"),
    text: t("deleteText"),
    icon: "warning",
    confirmButtonColor: "#01411c",
    cancelButtonColor: "#dc3545",
    confirmButtonText: t("deleteConfirmButtonText"),
    cancelButtonText: t("deleteCancelButtonText"),
  });

  if (result.value) {
    await removeFn(id);
  }
};

export default confirmDeleteDialog;
