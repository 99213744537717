import React, { useEffect } from 'react'
import { useStore } from "../../../stores/store";
import { useTranslation } from "react-i18next";


function Dashboard() {
    const { mainStore } = useStore();
    const { t } = useTranslation();

  
    useEffect(() => {
        mainStore.setMenuType(mainStore.DashboardUserMenuType);
        mainStore.setMenuNo(mainStore.DashboardUserMenuNo);
      }, []);

  return (
    <div className="container">
        <div className="row  d-flex justify-content-center">
            {/* <h3>User Dashboard</h3> */}
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ maxWidth: '800px', textAlign: 'center', marginBottom: '20px' }}>
          <p style={{ fontWeight: 'bold', color: '#006600' }}>
            We are in the process of developing the Eda System.
            As part of our transparent development approach, we will periodically publish updates.
          </p>
          <p style={{ fontWeight: 'bold', color: '#006600' }}>
            Please note, the design and functions of the system will evolve over time.
            The interactions between the functions may also change. 
          </p>
        </div>
      </div>
        </div>
    </div>
  )
}

export default Dashboard