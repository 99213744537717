import Select from "react-select";
import { useTranslation } from 'react-i18next';
import is from "date-fns/esm/locale/is/index.js";

const FormSelect = ({ label, options, register, name, errors, onChange, colSize = 4, required = true, value = undefined ,isMulti=false, isClearable=false}) => {
  const { t } = useTranslation();
  const colClass = `col-lg-${colSize} mb-3`;

  return (
    <div className={colClass}>
      <div className="form-group">
        <label className="labelDescription">
          <span className="labelText">{t(label)}</span>
          {required && <span className="labelStar text-danger fw-bold">*</span>}
        </label>
        <Select
          {...register(name)}
          options={options}
          onChange={onChange}
          value={value}
          isMulti={isMulti}
          isClearable={isClearable}
          className={`dropdown rounded-0 ${errors[name] ? "requiredInput" : ""}`}
        />
        {errors[name] && (
          <div className="error-message text-danger">
            {errors[name].message}
          </div>
        )}
      </div>
    </div>
  );
};

export default FormSelect;
