import React from 'react';
import { Route, Routes } from "react-router-dom";
import { observer } from "mobx-react-lite";

import Login from "./components/Login";
import PersistLogin from './components/PersistLogin';
import RequireAuth from './components/RequireAuth';
import NotFound from "./components/NotFound";


import Layout from './components/Common/Layout';
import MainLayout from "./components/Common/MainLayout";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        
        {/* public routes */}
        
        <Route path="/" element={<Login />} />
        <Route element={<PersistLogin />}>
        
          <Route element={<RequireAuth allowedRoles={['admin']} />}>
            {/* <Route path="/Home" element={<Home />} /> */}
            <Route path="/*" element={<MainLayout />} />
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default App;