import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../stores/store';
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useFetchData from '../../../hooks/useFetchData';
import { useSetMenu } from "../../../hooks/useSetMenu";

const NetworkGraph = observer(() => {
  const d3Container = useRef(null);
  const {mainStore,  timeLineStore } = useStore();
  const axiosPrivate = useAxiosPrivate();
  useFetchData(axiosPrivate, "/MergersAndAcquisitions/GetMAndAActivities", timeLineStore);
  useSetMenu(mainStore.NetworkGraphMenuType, mainStore.NetworkGraphMenuNo);

    useEffect(() => {
        if (timeLineStore.timeLine.length > 0) {
            const nodes = Array.from(new Set(timeLineStore.timeLine.flatMap(d => [d.acquirerName, d.targetName])), name => {
                const acquisitions = timeLineStore.timeLine.filter(event => event.acquirerName === name);
                const acquisitionsData = acquisitions.map(event => {
                  return {
                    acquirerName: event.acquirerName,
                    targetName: event.targetName,
                    aAndMDate: event.aAndMDate
                  };
                });
                return { id: name, acquisitions: acquisitionsData };
              });
            const links = timeLineStore.timeLine.map(d => ({
              source: d.acquirerName,
              target: d.targetName,
              value: d.aAndMDate
            }));
    
          const svg = d3.select(d3Container.current);
          svg.selectAll("*").remove(); // Clear any existing SVG elements
    
          // Define the zoom behavior
          const zoom = d3.zoom()
            .scaleExtent([1 / 2, 4]) // [zoom out, zoom in]
            .on('zoom', (event) => {
              // Zoom and pan the container
              container.attr('transform', event.transform);
            });
    
          // Apply the zoom behavior to the SVG
          svg.call(zoom);
    
          // Create a container for zooming and panning
          const container = svg.append('g');
    
          // Create the links
          const link = container.append("g")
            .attr("class", "links")
            .selectAll("line")
            .data(links)
            .enter().append("line")
            .attr("stroke-width", 2)
            .attr("stroke", "#999");
    
          // Create the nodes
          const node = container.append("g")
            .attr("class", "nodes")
            .selectAll("circle")
            .data(nodes)
            .enter().append("circle")
            .attr("r", 5)
            .attr("fill", "blue");
    
          // Add labels to each node
          const labels = container.append("g")
            .attr("class", "labels")
            .selectAll("text")
            .data(nodes)
            .enter().append("text")
            .attr("dx", 8)
            .attr("dy", ".35em")
            .text(d => d.id);
    
          // Define the simulation
          const simulation = d3.forceSimulation(nodes)
            .force('link', d3.forceLink(links).id(d => d.id))
            .force('charge', d3.forceManyBody())
            .force('center', d3.forceCenter(width / 2, height / 2));
    
          // Update positions on each simulation 'tick'
          simulation.on('tick', () => {
            link
              .attr("x1", d => d.source.x)
              .attr("y1", d => d.source.y)
              .attr("x2", d => d.target.x)
              .attr("y2", d => d.target.y);
    
            node
              .attr("cx", d => d.x)
              .attr("cy", d => d.y);
    
            labels
              .attr("x", d => d.x)
              .attr("y", d => d.y);
          });
    
          // Drag functionality for nodes
          const drag = d3.drag()
            .on('start', (event, d) => {
              if (!event.active) simulation.alphaTarget(0.3).restart();
              d.fx = d.x;
              d.fy = d.y;
            })
            .on('drag', (event, d) => {
              d.fx = event.x;
              d.fy = event.y;
            })
            .on('end', (event, d) => {
              if (!event.active) simulation.alphaTarget(0);
              d.fx = null;
              d.fy = null;
            });
            


            const tooltip = d3.select('body')
                    .append('div')
                    .attr('class', 'tooltip')
                    .style('opacity', 0)
                    .style('position', 'absolute')
                    .style('padding', '10px')
                    .style('background', 'white')
                    .style('border', '1px solid #ddd')
                    .style('border-radius', '5px')
                    .style('pointer-events', 'none')
                    .style('z-index', '10');

                    node.on('mouseover', (event, d) => {
                        // Assuming the first acquisition in the array for simplicity
                        const acquisition = d.acquisitions[0];
                        tooltip.transition()
                          .duration(200)
                          .style('opacity', 0.9);
                        tooltip.html(`Acquirer: ${acquisition.acquirerName}<br/>Target: ${acquisition.targetName}<br/>Date: ${d3.timeFormat("%B %d, %Y")(new Date(acquisition.aAndMDate))}`)
                          .style('left', `${event.pageX}px`)
                          .style('top', `${event.pageY - 28}px`);
                      })
                      .on('mouseout', () => {
                        tooltip.transition()
                          .duration(500)
                          .style('opacity', 0);
                      });


          node.call(drag);
        }
      }, [timeLineStore.timeLine]);
    
      // Define SVG width and height
      const width = 960;
      const height = 600;
    
      return (
        <svg
          className="d3-component"
          ref={d3Container}
          width={width}
          height={height}
        />
      );
    });
    
    export default NetworkGraph;