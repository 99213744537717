import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useLocalizedTextsMap = () => {
  const { t } = useTranslation();

  const localizedTextsMap = useMemo(() => ({
    columnMenuUnsort: t("columnMenuUnsort"),
    columnMenuSortAsc: t("columnMenuSortAsc"),
    columnMenuSortDesc: t("columnMenuSortDesc"),
    columnMenuFilter: t("columnMenuFilter"),
    columnMenuHideColumn: t("columnMenuHideColumn"),
    columnMenuShowColumns: t("columnMenuShowColumns"),
  }), [t]);

  return localizedTextsMap;
};

export default useLocalizedTextsMap;
