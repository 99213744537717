import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useStore } from "../../../stores/store";
import { observer } from "mobx-react-lite";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useEditData } from "../../../hooks/useEditData";
import FormLayout from '../../Generic/FormLayout';
import FormInput from '../../Generic/FormInput';
import { useSetMenu } from "../../../hooks/useSetMenu";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useFormSchema } from "../../../hooks/useFormSchema";
import * as yup from "yup";

const Edit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { mainStore } = useStore();
  const axiosPrivate = useAxiosPrivate();
  const { t } = useTranslation();
  
  useSetMenu(mainStore.IndustryMenuType, mainStore.IndustryMenuNo);
  const schema = yup.object({
    industryName: yup
      .string()
      .required(t('requiredField'))
      .max(200, t('maxLength', { max: 200 })),
   
  });

  const { register, handleSubmit, formState: { errors }, setValue } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = data => updateData(data);

  const { notFound, updateData } = useEditData(axiosPrivate, "/Industry", id, setValue, navigate);

  const goToList = () => {
    navigate("/Industry/List");
  };

  if (notFound) {
    return <div className="not-found-myDiv">
      <div className="not-found-container">
        <div className="not-found-content">
          <h1>{t("pageNotFoundTitle")}</h1>
          <p>{t("noDataWithIdMessage")}</p>
          <button onClick={goToList} className="btn btn-primary">
            {t("goTo") + " " + t("list") + " " + t("industries")}
          </button>
        </div>
      </div>
    </div>;
  }

  return (
    <FormLayout title={t("industryEditTitle")} onSubmit={handleSubmit(onSubmit)} onCancel={() => navigate(-1)}>
      <FormInput label={t("industryName")} name="industryName" register={register} errors={errors} />
      {/* Add other input fields if needed */}
    </FormLayout>
  );
};

export default observer(Edit);