import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
export const useSubmitData = (axiosInstance, endpoint, navigateList) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();
  return async (data) => {
    console.log(data);
    try {
      await axiosInstance.post(endpoint, data);
      console.log(data);
      enqueueSnackbar(t("createMessageSuccess"), { variant: "success" });
      navigate(navigateList);
    } catch (err) {
      enqueueSnackbar(t("createMessageError"), { variant: "error" });
    }
  };
};
