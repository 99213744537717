import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { sankey, sankeyLinkHorizontal } from 'd3-sankey';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../stores/store';
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useFetchData from '../../../hooks/useFetchData';
import { useSetMenu } from "../../../hooks/useSetMenu";

const SankeyDiagram = observer(() => {
  const d3Container = useRef(null);
  const { mainStore, timeLineStore } = useStore();
  const axiosPrivate = useAxiosPrivate();
  useFetchData(axiosPrivate, "/MergersAndAcquisitions/GetMAndAActivities", timeLineStore);
  useSetMenu(mainStore.SankeyDiagramMenuType, mainStore.SankeyDiagramMenuNo);
  useEffect(() => {
    if (timeLineStore.timeLine.length > 0) {
      // const svg = d3.select(d3Container.current);
      // svg.selectAll("*").remove();
  
      const width = 1500;
      const height = 3200;

     // Create zoom behavior
     const zoom = d3.zoom()
     .scaleExtent([0.5, 8]) // The scale extent to which you can zoom in and out
     .translateExtent([[0, 0], [1200, 800]]) // The extent to which you can pan
     .on('zoom', (event) => {
       g.attr('transform', event.transform); // Apply the zoom and pan transformation
     });

   // Select the SVG element and clear it
   const svg = d3.select(d3Container.current)
     .attr('viewBox', `0 0 1500 3200`) // Set the viewBox for responsive scaling
     .call(zoom); // Call the zoom behavior on the SVG

   svg.selectAll("*").remove();

   // Create a 'g' element that will hold all the Sankey elements
   const g = svg.append('g');
  
      // Set up a map for nodes to reference them by name
      const nodesByName = new Map();
  
      // First pass to create nodes
      timeLineStore.timeLine.forEach(d => {
        if (!nodesByName.has(d.acquirerName)) {
          nodesByName.set(d.acquirerName, { name: d.acquirerName });
        }
        if (!nodesByName.has(d.targetName)) {
          nodesByName.set(d.targetName, { name: d.targetName });
        }
      });
  
      // Convert the node map into an array and create an index map
      const nodes = Array.from(nodesByName.values());
      const nodeIndex = new Map(nodes.map((d, i) => [d.name, i]));
  
      // Second pass to create links using the node index
      const links = timeLineStore.timeLine.map(d => ({
        source: nodeIndex.get(d.acquirerName),
        target: nodeIndex.get(d.targetName),
        value: 1,
      })).filter(d => d.source !== undefined && d.target !== undefined);
  
      // Filter out circular links
      const nonCircularLinks = links.filter(link => link.source !== link.target);
  
      // Now we can create the sankey diagram
      const sankeyGraph = sankey()
        .nodeWidth(5)
        .nodePadding(10)
        .extent([[1, 1], [width - 1, height - 5]])({
          nodes: nodes.map(d => Object.assign({}, d)),
          links: nonCircularLinks,
        });
  
      // Draw the nodes
      svg.append('g')
        .selectAll("rect")
        .data(sankeyGraph.nodes)
        .enter().append("rect")
          .attr("x", d => d.x0)
          .attr("y", d => d.y0)
          .attr("height", d => d.y1 - d.y0)
          .attr("width", sankeyGraph.nodeWidth)
          .attr("fill", "#555");
  
      // Draw the links
      svg.append("g")
        .selectAll("path")
        .data(sankeyGraph.links)
        .enter().append("path")
          .attr("d", sankeyLinkHorizontal())
          .attr("stroke-width", d => Math.max(1, d.width))
          .attr("stroke", "#000")
          .attr("fill", "none")
          .attr("stroke-opacity", 0.5);

      svg.append("g")
        .style("font", "10px sans-serif")
        .selectAll("text")
        .data(sankeyGraph.nodes)
        .enter().append("text")
          .attr("x", d => d.x0 < width / 2 ? d.x1 + 6 : d.x0 - 6)
          .attr("y", d => (d.y1 + d.y0) / 2)
          .attr("dy", "0.35em")
          .attr("text-anchor", d => d.x0 < width / 2 ? "start" : "end")
          .text(d => d.name);
    }
  }, [timeLineStore.timeLine]);

  return (
    <div>
      <svg
        ref={d3Container}
        style={{ width: '100%', height: '100%', minHeight: '800px', border: '1px solid #ccc' }}
      />
    </div>
  );
});

export default SankeyDiagram;
