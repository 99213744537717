import { makeAutoObservable, runInAction } from "mobx";

export default class industryStore {
  industry = [];

  constructor() {
    makeAutoObservable(this);
  }

  setData = (data) => {
    this.industry = [];
    runInAction(() => {
      this.industry = data;
    });
  };

  deleteIndustry = (id) => {
    let newState = this.industry.filter((industry) => industry.id !== id);
    this.industry = [];
    this.industry = newState;
  };
}
