import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/store";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AuthContext from "../../context/AuthProvider";
const MainSideBar = () => {
  const { t } = useTranslation();
  const { mainStore } = useStore();

  const { auth } = useContext(AuthContext);

  const handleClick = async () => {
    mainStore.setIsMobileMenuOpen(1);
  }

  // Define menu items
  const adminMenuItems = [
    {
      path: "/Settings/Dashboard",
      displayName: t("dashboard"),
      iconClass: "bi bi-grid-3x3-gap-fill",
      roles: ["admin"],
      menuNo: mainStore.DashboardSettingsMenuNo,
    },
    {
      path: "/Industry/List",
      displayName: t("industry"),
      iconClass: "bi bi-grid-3x3-gap-fill",
      roles: ["admin"],
      menuNo: mainStore.IndustryMenuNo,
    },
    {
      path: "/Company/List",
      displayName: t("company"),
      iconClass: "bi bi-grid-3x3-gap-fill",
      roles: ["admin"],
      menuNo: mainStore.CompanyMenuNo,
    },
    {
      path: "/MergeAndAcquisition/List",
      displayName: t("mergeAndAcquisition"),
      iconClass: "bi bi-grid-3x3-gap-fill",
      roles: ["admin"],
      menuNo: mainStore.MergerAndAcquisitionMenuNo,
    },
    {
      path: "/Graphs/MAndATimelineGraph",
      displayName: t("MAndATimelineGraphTimeLine"),
      iconClass: "bi bi-grid-3x3-gap-fill",
      roles: ["admin"],
      menuNo: mainStore.MAndATimelineGraphMenuNo,
    },
    {
      path: "/Graphs/NetworkGraph",
      displayName: t("MAndATimelineGraphNetworkGraph"),
      iconClass: "bi bi-grid-3x3-gap-fill",
      roles: ["admin"],
      menuNo: mainStore.NetworkGraphMenuNo,
    },
    {
      path: "/Graphs/SankeyDiagram",
      displayName: t("MAndATimelineGraphNSankeyDiagram"),
      iconClass: "bi bi-grid-3x3-gap-fill",
      roles: ["admin"],
      menuNo: mainStore.SankeyDiagramMenuNo,
    },
    // {
    //   path: "/RoleList",
    //   displayName: t("roles"),
    //   iconClass: "ti ti-world",
    //   roles: ["admin"],
    //   menuNo: mainStore.RoleMenuNo,
    // },
    // {
    //   path: "/UserList",
    //   displayName: t("users"),
    //   iconClass: "ti ti-world",
    //   roles: ["admin"],
    //   menuNo: mainStore.UserMenuNo,
    // },
    // Add other menu items as needed
  ];

  const renderMenuItems = () => {
    return adminMenuItems
      .filter(item => item.roles.includes(auth?.role))
      .map(item => (
        <li className="nav-item" key={item.path}>
          <Link
            to={item.path}
            className={`nav-link ${mainStore.menuNo === item.menuNo ? "active" : ""}`}
          >
            <span className="nav-link-text ms-1"> <i className={`${item.iconClass} me-2`}></i>{item.displayName}</span>
          </Link>
        </li>
      ));
  };

  return (
    <>
      <aside className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 bg-white" id="sidenav-main">
        <div className="sidenav-header ">
          <i className="bi bi-x-lg p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-xl-none" aria-hidden="true" id="iconSidenav" onClick={handleClick}></i>

          <Link
            to="/Dashboard"
            className="navbar-brand d-flex justify-content-center mt-2 mb-2"
          >
            <img src={"/assets/img/eda.png"} alt="logo" className="navbar-brand-img" />
          </Link>
        </div>
        <hr className="horizontal dark mt-0 " />
        <div className="collapse navbar-collapse  w-auto " id="sidenav-collapse-main">
          <ul className="navbar-nav">
            {renderMenuItems()}

          </ul>
        </div>
      </aside>
    </>
  );
};

export default observer(MainSideBar);
