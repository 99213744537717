import { makeAutoObservable, runInAction } from "mobx";

export default class mergerAndAcquisitionStore {
mergerAndAcquisition = [];

  constructor() {
    makeAutoObservable(this);
  }

  setData = (data) => {
    this.mergerAndAcquisition = [];
    runInAction(() => {
      this.mergerAndAcquisition = data;
    });
  };

  deletemergerAndAcquisition = (id) => {
    let newState = this.mergerAndAcquisition.filter((mergerAndAcquisition) => mergerAndAcquisition.id !== id);
    this.mergerAndAcquisition = [];
    this.mergerAndAcquisition = newState;
  };
}
