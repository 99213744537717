import { axiosPrivate } from "../api/axios";
import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

const useAxiosPrivate = () => {
    const refresh = useRefreshToken();
    const { auth, setAuth} = useAuth();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const requestIntercept = axiosPrivate.interceptors.request.use(
            config => {
                if (!config.headers['Authorization']) {
                    config.headers['Authorization'] = `Bearer ${auth?.accessToken}`;
                }
                return config;
            }, (error) => Promise.reject(error)
        );

        const responseIntercept = axiosPrivate.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config;
                if (error?.response?.status === 418 && !prevRequest?.sent) {
                    prevRequest.sent = true;
                    try {
                        const newAccessToken = await refresh();
                        prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                        return axiosPrivate(prevRequest);
                    } catch (refreshError) {
                        return Promise.reject(refreshError);
                    }
                }else if (error?.response?.status === 401) {
                    setAuth({});
                    navigate('/');
                }
                else if(error?.response?.status === 0){
                    //console.log("CancelResponse:"+error);
                }
                else if(error?.request?.status === 0){
                    //console.log("CancelRequest:"+error);
                }
                else if(error?.response?.status === 404){
                    console.log("Not Found:"+error);
                }
                else{
                    console.log(error);
                }
          

                return Promise.reject(error);
            }
        );

        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        }
        // eslint-disable-next-line
    }, [auth, refresh, navigate, enqueueSnackbar]);

    return axiosPrivate;
}

export default useAxiosPrivate;
