import React from 'react';
import { Link } from 'react-router-dom';
import SearchBar from './SearchBar';

const ListLayout = ({
  title, 
  searchQuery, 
  onSearchChange, 
  searchPlaceholder, 
  addButtonLink, 
  addButtonTitle, 
  children,
  hasAddButton=true,
}) => {
  return (
    <>
      <div className="row">
        <div className="col-lg-12 mb-lg-0 mb-4">
          <div className="card rounded-0">
            <div className="card-header pb-0">
              <p className="crudTitle">{title}</p>
            </div>
            <div className="card-body p-3">
              <div className="row">
                <div className="container">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-md-6 col-12 order-md-1 order-2 mt-md-0 my-2">
                      <SearchBar
                        value={searchQuery}
                        onChange={onSearchChange}
                        placeholder={searchPlaceholder}
                      />
                    </div>
                    {hasAddButton && <div className="col-md-3 col-12 order-md-2 order-1">
                      <Link
                        to={addButtonLink}
                        className="btn btn-outline-primary btn-md w-100 mb-0 me-3 mb-1"
                      > 
                        <i className="bi bi-plus-circle-fill" aria-hidden="true"></i> {addButtonTitle}
                      </Link>
                    </div>}
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-12 mt-2 ">
                      {children} 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListLayout;
