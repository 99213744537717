import { useMemo } from 'react';

const useFilterData = (data, searchQuery) => {
  return useMemo(() => {
    if (!searchQuery) return data;
    return data.filter(item =>
      Object.values(item).some(value =>
        value !== null && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [data, searchQuery]);
};

export default useFilterData;
