import React, { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import { observer } from "mobx-react-lite";
import RequireAuth from '../RequireAuth';
import AuthContext from "../../context/AuthProvider";
import MainSideBar from "./MainSideBar";
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";
import Layout from './Layout';
import NotFound from "../NotFound";
import DashboardUser from "../User/Dashboard/Dashboard";

import DashboardSettings from "../Admin/Dashboard/Dashboard";


import IndustryList from "../Admin/Industry/List";
import AddIndustry from "../Admin/Industry/Add";
import EditIndustry from "../Admin/Industry/Edit";

import CompanyList from "../Admin/Company/List";
import AddCompany from "../Admin/Company/Add";
import EditCompany from "../Admin/Company/Edit";

import MergeAndAcquisitionList from "../Admin/MergeAndAcquisition/List";
import AddMergeAndAcquisition from "../Admin/MergeAndAcquisition/Add";
import EditMergeAndAcquisition from "../Admin/MergeAndAcquisition/Edit";
import MAndATimelineGraph from "../Admin/Graphs/MAndATimelineGraph";
import NetworkGraph from "../Admin/Graphs/NetworkGraph";
import SankeyDiagram from "../Admin/Graphs/SankeyDiagram";


const MainLayout = () => {
  const { auth } = useContext(AuthContext);

  const getRoutes = () => {
    const routes = [

        { path: "/Settings/Dashboard", element: <DashboardSettings />, roles: ["admin"] },
        { path: "/Dashboard", element: <DashboardUser />, roles: ["admin"] },

        { path: "/Industry/List", element: <IndustryList />, roles: ["admin"] },
        { path: "/Industry/Add", element: <AddIndustry />, roles: ["admin"] },
        { path: "/Industry/Edit/:id", element: <EditIndustry />, roles: ["admin"] },

        { path: "/Company/List", element: <CompanyList />, roles: ["admin"] },
        { path: "/Company/Add", element: <AddCompany />, roles: ["admin"] },
        { path: "/Company/Edit/:id", element: <EditCompany />, roles: ["admin"] },

        { path: "/MergeAndAcquisition/List", element: <MergeAndAcquisitionList />, roles: ["admin"] },
        { path: "/MergeAndAcquisition/Add", element: <AddMergeAndAcquisition />, roles: ["admin"] },
         { path: "/MergeAndAcquisition/Edit/:id", element: <EditMergeAndAcquisition />, roles: ["admin"] },

        { path: "/Graphs/MAndATimelineGraph", element: <MAndATimelineGraph />, roles: ["admin"] },
        { path: "/Graphs/NetworkGraph", element: <NetworkGraph />, roles: ["admin"] },
        { path: "/Graphs/SankeyDiagram", element: <SankeyDiagram />, roles: ["admin"] },
         

    ]

return routes
  .filter(route => route.roles.includes(auth?.role))
  .map(route => (
    <Route key={route.path} exact path={route.path} element={route.element} />
  ));
  };

return (
  <>
    <MainSideBar />
    <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
      <div className="container-fluid py-4">
        <MainHeader />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route element={<RequireAuth allowedRoles={['admin']} />}>
              {getRoutes()}
            </Route>
          </Route>
          <Route exact path="/*" element={<NotFound />} />
        </Routes>
        <MainFooter />
      </div>
    </main>
  </>
);
};
export default observer(MainLayout);
