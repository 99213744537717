import { makeAutoObservable, runInAction } from "mobx";

export default class timeLineStore {
    timeLine = [];

  constructor() {
    makeAutoObservable(this);
  }

  setData = (data) => {
    this.timeLine = [];
    runInAction(() => {
      this.timeLine = data;
    });
  };

//   deletemergerAndAcquisition = (id) => {
//     let newState = this.timeLine.filter((timeLine) => timeLine.id !== id);
//     this.timeLine = [];
//     this.timeLine = newState;
//   };
}
