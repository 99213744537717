import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useEditData } from "../../../hooks/useEditData";
import FormLayout from '../../Generic/FormLayout';
import FormInput from '../../Generic/FormInput';
import FormSelect from '../../Generic/FormSelect';
import { useStore } from "../../../stores/store";
import { useSetMenu } from "../../../hooks/useSetMenu";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { format } from "date-fns";
const Edit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { mainStore } = useStore();
  const axiosPrivate = useAxiosPrivate();
  const { t } = useTranslation();
  useSetMenu(mainStore.MergerAndAcquisitionMenuType, mainStore.MergerAndAcquisitionMenuNo);

  const schema = yup.object({
    companyId: yup.number().required(t("selectRequired")),
    companyMAndAById: yup.number().required(t("selectRequired")),
    foundCompYear: yup.date().required(t('requiredField')),
    aAndMDate: yup.date().required(t('requiredField')),
    lifetime: yup.number().positive(t('positiveNumber')).integer(t('integerNumber')).typeError(t('validNumber')),
    // infoCompanyFound: yup.string(),
    // infoWhenMerged: yup.string(),
    // otherDetails: yup.string(),
    // bmFound: yup.string(),
    // bmMerged: yup.string(),
    // Add additional validations if necessary
  });

  const { register, handleSubmit,watch, formState: { errors }, setValue, getValues } = useForm({ resolver: yupResolver(schema) });

// Watch the two date fields
const foundCompYear = watch("foundCompYear");
const aAndMDate = watch("aAndMDate");

// This effect will re-calculate the lifetime when the dates change
useEffect(() => {
  // Check if both dates are valid
  if (foundCompYear && aAndMDate) {
    const startDate = new Date(foundCompYear);
    const endDate = new Date(aAndMDate);
    
    // Calculate the difference in days
    const diffTime = Math.abs(endDate - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    // Set the lifetime field value
    setValue("lifetime", diffDays);
  }
}, [foundCompYear, aAndMDate, setValue]);
 
  // const onSubmit = data => updateData(data);
  const onSubmit = async data => {
    const formattedData = {
      ...data,
      foundCompYear:format(new Date(data.foundCompYear), "yyyy-MM-dd"),
      aAndMDate:format(new Date(data.aAndMDate), "yyyy-MM-dd"),
      lifetime: undefined
    };
    await updateData(formattedData)
  }

  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedCompany2, setSelectedCompany2] = useState(null);

  const additionalDataFetch = async (Data) => {
    try {
        console.log(Data);
      const response = await axiosPrivate.get("/Company/SelectOptions");
      setCompanies(response.data);
      const selected = response.data.find(c => c.value === Data.companyId.toString());
      setSelectedCompany(selected);
      const selected2 = response.data.find(c => c.value === Data.companyMAndAById.toString());
      setSelectedCompany2(selected2);

      const formattedFoundCompYear = Data.foundCompYear ? Data.foundCompYear.split('T')[0] : '';
        const formattedAAndMDate = Data.aAndMDate ? Data.aAndMDate.split('T')[0] : '';

        setValue("foundCompYear",formattedFoundCompYear);
        setValue("aAndMDate",formattedAAndMDate);


    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

const handleCompany1Change = selectedOption => {
    setSelectedCompany(selectedOption);
    setValue("companyId", selectedOption.value);
  };
  
const handleCompany2Change = selectedOption => {
    setSelectedCompany2(selectedOption);
    setValue("companyMAndAById", selectedOption.value);
  };
  const { notFound, updateData } = useEditData(axiosPrivate, "/MergersAndAcquisitions", id, setValue, navigate, additionalDataFetch);

  if (notFound) {
    return <div>{t("dataNotFoundMessage")}</div>; // Simplified not found message
  }

  

  return (
    <FormLayout title={t("mergersAndAcquisitionsEditTitle")} onSubmit={handleSubmit(onSubmit)} onCancel={() => navigate("/MergersAndAcquisitions/List")}>
      <FormSelect label={t("foundCompanyName")} name="companyId" options={companies} value={selectedCompany} onChange={handleCompany1Change}  register={register} errors={errors} />
      <FormSelect label={t("MACompanyName")} name="companyMAndAById" options={companies} value={selectedCompany2} onChange={handleCompany2Change} register={register} errors={errors} />
      <FormInput label={t("foundCompYear")} name="foundCompYear" type="date" register={register} errors={errors} />
      <FormInput label={t("MADate")} name="aAndMDate" type="date" register={register} errors={errors} />
      <FormInput label={t("lifetime")} name="lifetime" type="number" register={register} errors={errors} />
      <FormInput label={t("infoCompanyFound")} name="infoCompanyFound" register={register} errors={errors} />
      <FormInput label={t("infoWhenMerged")} name="infoWhenMerged" register={register} errors={errors} />
      <FormInput label={t("otherDetails")} name="otherDetails" register={register} errors={errors} />
      <FormInput label={t("bmFound")} name="bmFound" register={register} errors={errors} />
      <FormInput label={t("bmMerged")} name="bmMerged" register={register} errors={errors} />
    </FormLayout>
  );
};

export default Edit;
