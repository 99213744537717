import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useEditData } from "../../../hooks/useEditData";
import FormLayout from '../../Generic/FormLayout';
import FormInput from '../../Generic/FormInput';
import FormSelect from '../../Generic/FormSelect';
import { useStore } from "../../../stores/store";
import { useSetMenu } from "../../../hooks/useSetMenu";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const Edit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { mainStore } = useStore();
  const axiosPrivate = useAxiosPrivate();
  const { t } = useTranslation();
  useSetMenu(mainStore.CompanyMenuType, mainStore.CompanyMenuNo);

  const schema = yup.object({
    foundCompName: yup.string().required(t('requiredField')).max(200, t('maxLength', { max: 200 })),
    foundCompYear: yup.number().required(t('requiredField')).positive(t('positiveNumber')).integer(t('integerNumber')).typeError(t('validNumber')),
    industryId: yup.string().required(t("selectRequired")),
    // Add additional validations if necessary
  });

  const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm({ resolver: yupResolver(schema) });
  const onSubmit = data => updateData(data);

  const [industries, setIndustries] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  
  const additionalDataFetch = async (companyData) => {
    try {
      const industriesResponse = await axiosPrivate.get("/Industry/SelectOptions");
      setIndustries(industriesResponse.data);
      const selected = industriesResponse.data.find(i => i.value === companyData.industryId.toString());
      setSelectedIndustry(selected);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleIndustryChange = selectedOption => {
    setSelectedIndustry(selectedOption);
    setValue("industryId", selectedOption.value);
  };

  const { notFound, updateData } = useEditData(axiosPrivate, "/Company", id, setValue, navigate, additionalDataFetch);

  if (notFound) {
    return <div>{t("dataNotFoundMessage")}</div>; // Simplified not found message
  }

  return (
    <FormLayout title={t("companyEditTitle")} onSubmit={handleSubmit(onSubmit)} onCancel={() => navigate("/Company/List")}>
      <FormInput label={t("companyName")} name="foundCompName" register={register} errors={errors} />
      <FormInput label={t("companyFoundYear")} name="foundCompYear" register={register} errors={errors} type="number" />
      <FormSelect label={t("industry")} name="industryId" options={industries} value={selectedIndustry} onChange={handleIndustryChange} register={register} errors={errors} />
      {/* Add additional inputs as needed */}
    </FormLayout>
  );
};

export default Edit;
