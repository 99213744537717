import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

export const useFormSchema = () => {
  const { t } = useTranslation();

  return yup.object({
    nameLNG1: yup
    .string()
    .required(t('requiredField'))
    .max(50, t('maxLength', { max: 50 })),
  nameLNG2: yup
    .string()
    .required(t('requiredField'))
    .max(50, t('maxLength', { max: 50 })),
  nameLNG3: yup
    .string()
    .required(t('requiredField'))
    .max(50, t('maxLength', { max: 50 })),

  });
};
